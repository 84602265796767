import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

class News extends Component {
    render() {
        return (
            <Layout>
                <SEO title="News" />
                    <div>
                        News
                    </div>
            </Layout>
        )
    }
}

export default News